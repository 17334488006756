import { IPageOptions } from "./../../../../@models/PageOptions";
import { Injectable } from "@angular/core";
import { ApiService } from "@core/api-service";
import { NotificationPaths } from "../api-notification-paths";
import { Observable } from "rxjs";
import { PaginatedListOfNotificationVm } from "../models/responders/view-models/PaginatedListOfNotificationVm";

@Injectable()
export class NotificationClientService {
    constructor(private http: ApiService) { }

    getPage(
        pageOptions: IPageOptions
    ): Observable<PaginatedListOfNotificationVm> {
        const url_ = NotificationPaths.GetPage;

        return this.http.getPage(url_, pageOptions);
    }

    markAsRead(id: number): Observable<void> {
        let url_ = NotificationPaths.MarkAsRead;

        url_ = url_.replace("{id}", encodeURIComponent("" + id));

        return this.http.put(url_, null);
    }

    markAllAsRead(): Observable<void> {
        const url_ = NotificationPaths.MarkAllAsRead;

        return this.http.delete(url_);
    }
}
