import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, retryWhen, tap } from "rxjs/operators";
import { TokenService } from "@core/auth/services/token.service";
import { PATHS } from "@models";
import { LocalStorageService } from "@core/services/local-storage.service";

// export const APP_VERSION = new InjectionToken<string>("APP_VERSION");

@Injectable({
  providedIn: "root",
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    // @Optional() @Inject(APP_VERSION) private readonly _version: string,
    private _router: Router,
    private _tokenService: TokenService,
    private _storageService: LocalStorageService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headers: any = {
      // Version: this._version,
      "Accept-Language": localStorage.getItem("lang"),
      "Application-Type": "User",
      "Device-Token": this.generateDeviceToken(),
    };

    const tempToken = this._storageService.get("temp");
    
    const token = tempToken || this._tokenService.token.value;

    const retryLimit = 3;
    let attempt = 0;

    this._storageService.remove("temp");

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const modifiedReq = req.clone({ setHeaders: headers });

    return next.handle(modifiedReq).pipe(
      retryWhen((errors) =>
      errors.pipe(
        // inside the retryWhen, use a tap operator to throw an error
        // if you don't want to retry
        tap((error) => {
            if (++attempt >= retryLimit || (error.status < 500 && error.status !== 403)) {
              throw error;
            }
          })
        )
      ),
      catchError((err) => this.handleErrorReq(err))
    );
  }

  private handleErrorReq(err: HttpErrorResponse): Observable<never> {
    switch (err.status) {
      case 401:
        // this._identityManager
        //   .processLogout(PATHS.Login, this._router.url)
        //   .subscribe();
        break;
      case 500:
        this._router.navigateByUrl(PATHS.Page500).then();
        break;
    }

    return throwError(err);
  }

  private generateDeviceToken(): string {
    let d = new Date().getTime();

    // Time in microseconds since page-load or 0 if unsupported
    let d2 =
      (typeof performance !== "undefined" &&
        performance.now &&
        performance.now() * 1000) ||
      0;

    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        let r = Math.random() * 16; // random number between 0 and 16

        // Use timestamp until depleted
        if (d > 0) {
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        }
        // Use microseconds since page-load if supported
        else {
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
  }
}
