import { Inject, Injectable, Injector } from "@angular/core";
import { LOCATION_INITIALIZED, APP_BASE_HREF, DOCUMENT } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { Lang } from "@core/settings/language.type";
import { getLangDir, LanguagesOptions } from "@core/settings/language.helpers";

@Injectable({
  providedIn: "root",
})
export class TranslateLoaderService {
  constructor(
    private readonly _injector: Injector,
    private readonly _translate: TranslateService,
    @Inject(APP_BASE_HREF) private readonly _baseHref: Lang,
    @Inject(DOCUMENT) private readonly dom: Document
  ) {}

  load() {
    return new Promise<any>((resolve: any) => {
      const locationInitialized = this._injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );

      locationInitialized.then(() => {
        this._translate.addLangs(LanguagesOptions.map((x) => x.name));
        const lang = this._baseHref;
        this._translate.setDefaultLang(lang);
        this._translate.use(lang);
        this.changeDirectionality(lang, getLangDir(lang));

        return resolve(null);
      });
    });
  }

  changeDirectionality(lang: Lang, dir: any): void {
    // html dir & lang
    const htmlTag = this.dom.getElementsByTagName("html")[0];
    htmlTag.setAttribute("lang", lang);
    htmlTag.setAttribute("dir", dir);

    const bodyTag = this.dom.getElementsByTagName("body")[0];
    bodyTag.setAttribute("dir", dir);

    if (dir === "rtl") {
      document.documentElement.classList.remove("english");
      document.documentElement.classList.add("arabic");
    } else {
      document.documentElement.classList.add("english");
      document.documentElement.classList.remove("arabic");
    }
  }
}
