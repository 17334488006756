import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SidnavComponent } from "./sidnav.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconRegistry } from "@angular/material/icon";
import { CustomIconRegistry } from "@core/icons";
import { SvgSidNavIconsProviders } from "./svg-sidnav-icon-provider";

@NgModule({
  declarations: [SidnavComponent],
  imports: [CommonModule, RouterModule, TranslateModule],
  exports: [SidnavComponent],
  providers: [
    ...SvgSidNavIconsProviders,
    { provide: MatIconRegistry, useClass: CustomIconRegistry },
  ],
})
export class SidnavModule {}
