<div *ngIf="loaded" class="d-flex flex-column min-vh-100 overflow-hidden">
  <div>
    <app-header></app-header>
  </div>

  <div class="flex-grow-1">
    <router-outlet></router-outlet>
  </div>

  <div>
    <app-footer></app-footer>
  </div>

  <app-sidnav class=" lg:hidden"></app-sidnav>
</div>
