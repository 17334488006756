import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable, throwError, BehaviorSubject, of, from } from "rxjs";
import { map, tap, catchError, switchMap, finalize } from "rxjs/operators";
import { ACTIONS, QUERY_PARAMETER_NAMES } from "@models";
import { TokenService } from "@core/auth";
import { AuthToken } from "./token";
import { AuthResult } from "@core/auth";
import { ApiHandlerService } from "@core/services/api-handler.service";
import { LocalStorageService } from "@core/services/local-storage.service";
import { ViewportScroller } from "@angular/common";
import { Messaging, getToken } from "@angular/fire/messaging";
import { environment } from "src/environments/environment";
import { AccountClientService } from "@core/auth/services/account-client.service";
import { DeviceTokensAddDeviceTokenCommand } from "@models/api/commands/DeviceTokensAddDeviceTokenCommand";
import { RefreshTokenCommand } from "../models/commands/RefreshTokenCommand";
import { AccountDto } from "../models/responders/dtos/AccountDto";
import { User } from "../models/user";
import { AuthResponse } from "../models/AuthResponse";




@Injectable({
  providedIn: "root",
})
export class IdentityManager {
  _account$: BehaviorSubject<AccountDto> = new BehaviorSubject<AccountDto>(
    null
  );

  private _tokenExpirationTimer: any;

  constructor(
    private readonly _accountClientService: AccountClientService,
    private readonly _tokenService: TokenService,
    private readonly _router: Router,
    private readonly _handler: ApiHandlerService,
    private readonly _storageService: LocalStorageService,
    private _scroller: ViewportScroller,
    private messaging: Messaging
  ) {}

  getUser(): User {
    return this._tokenService.token.payload
      ? new User(this._tokenService.token.payload)
      : null;
  }

  get user(): User {
    return this._tokenService.token.payload
      ? new User(this._tokenService.token.payload)
      : null;
  }

  public initAccount(): void {
    this._accountClientService
      .get()
      .subscribe((account: AccountDto) => this._account$.next(account));
  }

  public refreshToken(
    currentUrl: string,
    returnUrl: string
  ): Observable<AuthResult> {
    return this.processResultToken(
      this._accountClientService.refreshToken(
        new RefreshTokenCommand({
          refreshToken: this._tokenService.refreshToken,
        })
      ),
      true
    ).pipe(
      catchError(() => {
        return this.forceLogout(currentUrl, returnUrl).pipe(
          map(() => new AuthResult(null, null))
        );
      })
    );
  }

  public logout(currentUrl: string, returnUrl: string): Observable<boolean> {
    if (!this.getUser()) {
      return this.forceLogout(currentUrl, returnUrl);
    }

    return this._accountClientService.logout().pipe(
      map(() => true),
      finalize(() => this.forceLogout(currentUrl, returnUrl)),
      catchError(() => {
        return of(true);
      })
    );
  }

  private forceLogout(
    currentUrl: string,
    returnUrl?: string
  ): Observable<boolean> {
    clearTimeout(this._tokenExpirationTimer);

    this._storageService.remove("refresh");
    this._tokenService.clear();

    const currentUrlTree = this._router.parseUrl(currentUrl);

    delete currentUrlTree.queryParams[QUERY_PARAMETER_NAMES.Action];
    delete currentUrlTree.queryParams[QUERY_PARAMETER_NAMES.ReturnUrl];

    const cleanUrl = this._router.serializeUrl(currentUrlTree);

    this._scroller.scrollToPosition([0, 0]);
    if (returnUrl) {
      const param1 = QUERY_PARAMETER_NAMES.Action + "=" + ACTIONS.Login;
      const param2 = QUERY_PARAMETER_NAMES.ReturnUrl + "=" + returnUrl;
      const params = `${param1}&${param2}`;

      const newUrl = cleanUrl.includes("?")
        ? cleanUrl + `&${params}`
        : cleanUrl + `?${params}`;

      return from(this._router.navigateByUrl(newUrl).then());
    } else {
      return from(this._router.navigateByUrl(cleanUrl).then());
    }
  }

  private processLogout(
    currentUrl: string,
    returnUrl?: string
  ): Observable<boolean> {
    const refresh = !!this._storageService.get("refresh");

    if (refresh) {
      return this.refreshToken(currentUrl, returnUrl).pipe(
        switchMap((result: AuthResult) => {
          if (!!result.user) {
            return of(true);
          }

          return this.logout(currentUrl, returnUrl).pipe(map(() => false));
        })
      );
    }

    return this.logout(currentUrl, returnUrl).pipe(map(() => false));
  }

  public authorize(authToken: AuthToken, rememberMe: boolean): void {
    if (!authToken) {
      this._tokenService.clear();
      return;
    }

    this._tokenService.set(authToken);

    this.initAccount();

    if (rememberMe) {
      this._storageService.set("refresh", "1");
    }

    this._tokenExpirationTimer = setTimeout(
      this.processLogout,
      24 * 60 * 60 * 1000
    );
  }

  /** Precess result. Save token if success result. Set logout timer */
  //TODO: it should be private soon.
  public processResultToken(
    tokenObservable: Observable<AuthResponse>,
    rememberMe: boolean
  ): Observable<AuthResult> {
    return tokenObservable.pipe(
      map((token: AuthResponse) => new AuthToken(token)),
      tap((authToken) => this.authorize(authToken, rememberMe)),
      tap(() => {
        navigator.serviceWorker
          .register("firebase-messaging-sw.js", { type: "module", scope: "__" })
          .then((serviceWorkerRegistration) =>
            getToken(this.messaging, {
              serviceWorkerRegistration,
              vapidKey: environment.vapidKey,
            })
          )
          .then((fcmToken) => {
            if (fcmToken) {
              this._accountClientService
              .postDeviceToken(
                  new DeviceTokensAddDeviceTokenCommand({
                    deviceToken: fcmToken,
                  })
                )
                .subscribe();
            }
          });
      }),
      map((authToken) => new AuthResult(new User(authToken.payload), null)),
      catchError((err) => {
        return throwError(new AuthResult(null, this._handler.handleError(err)));
      })
    );
  }
}
