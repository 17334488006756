import { Howl, Howler } from "howler";
import { IdentityManager } from "./../@core/auth/services/identity-manager.service";
import { PATHS } from "@models";
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { LocalizationService } from "@core/services/localization.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { NavigationEnd, Router } from "@angular/router";
import { FilterService, SellingDetails } from "@core/services/filter.service";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { RealTimeService } from "@core/services/real-time.service";
import { AccountDto } from "@core/auth/models/responders/dtos/AccountDto";

@UntilDestroy()
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @ViewChild("menu") menu: ElementRef;
  @ViewChild("menu2") menu2: ElementRef;
  @ViewChild("toggleButton") toggleButton: ElementRef;
  @ViewChild("toggleButton2") toggleButton2: ElementRef;
  @Output() login: EventEmitter<string> = new EventEmitter();

  notifications: boolean = false;

  Paths = PATHS;
  account: AccountDto;
  lang: string;
  SellingDetails = SellingDetails;
  sale: boolean;
  rent: boolean;
  activeNotifications;

  constructor(
    private _identityManager: IdentityManager,
    private _localizationService: LocalizationService,
    private _filterService: FilterService,
    private _router: Router,
    private translateSer: TranslateService,
    private renderer: Renderer2,
    private _realTimeService: RealTimeService
  ) {
    this.lang = this.translateSer.currentLang;

    this.renderer.listen("window", "click", (e: Event) => {
      if (
        e.target !== this.toggleButton?.nativeElement &&
        e.target !== this.toggleButton2?.nativeElement &&
        e.target !== this.menu?.nativeElement &&
        e.target !== this.menu2?.nativeElement
      ) {
        this.notifications = false;
      }
    });
  }

  ngOnInit(): void {
    this.activeLink(this._router.url);

    this._router.events.pipe(untilDestroyed(this)).subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.activeLink(event.url);
      }
    });

    this._identityManager._account$.pipe(untilDestroyed(this)).subscribe(
      (details: AccountDto) => {
        this.account = details;
        this.activeNotifications = details?.newNotificationNumber;
      },
      (err) => {
        console.log("err :>> ", err);
      }
    );

    this._realTimeService.onNotification((result: any) => {
      if (result?.data) {
        this.activeNotifications++;

        const sound = new Howl({
          src: ["assets/audio/notify.mp3"],
        });

        sound.once("load", function () {
          sound.play();
        });
      }
    });
  }

  changeLang(): void {
    this._localizationService.changeLang();
  }

  toggle(): void {
    if (window.screen.width < 793) {
      document.querySelector("html").classList.toggle("show-menu");
    } else {
      document.querySelector("html").classList.remove("show-menu");
    }
  }

  scrolling() {
    const sticky = document.querySelector("header");
    const scroll = window.screenTop;

    if (scroll >= 15) sticky.classList.add("fixed");
    else sticky.classList.remove("fixed");
  }

  showNotifications() {
    this.notifications = !this.notifications;
  }

  async logout(): Promise<any> {
    const confirmLogout = this.translate("account.confirmLogout");
    const confirm = this.translate("general.continue");
    const noConfirm = this.translate("general.cancel");

    Swal.fire({
      title: await confirmLogout,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#024870",
      cancelButtonText: await noConfirm,
      confirmButtonText: await confirm,
    }).then((result) => {
      if (result.isConfirmed) {
        this._identityManager
          .logout(PATHS.Home, "")
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this._identityManager._account$.next(null);
          });
      }
    });
  }

  translate(key: string): Promise<string> {
    return this.translateSer.get(key).toPromise();
  }

  _login(provider: string) {
    this.login.emit(provider);
  }

  saleRent(type: SellingDetails) {
    switch (type) {
      case SellingDetails.Sale:
        this._router.navigate([PATHS.ForSale]);
        this._filterService.saleOrRent.next(SellingDetails.Sale);
        break;
      case SellingDetails.Rent:
        this._router.navigate([PATHS.ForRental]);
        this._filterService.saleOrRent.next(SellingDetails.Rent);
        break;
    }
  }

  activeLink(url: string) {
    this.sale = url.includes("forSale");
    this.rent = url.includes("forRental");
  }

  resetActiveNotification(evt: number): void {
    if (evt == 0) {
      this.activeNotifications = evt;
    } else {
      this.activeNotifications += evt;
    }
  }
}
