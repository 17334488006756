import {
  ErrorHandler,
  InjectionToken,
  Inject,
  Injectable,
  Optional,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { of, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";

/**
 * Use SVG_ICONS (and SvgIconInfo) as "multi" providers to provide the SVG source
 * code for the icons that you wish to have preloaded in the `CustomIconRegistry`
 */

export const SVG_ICONS: InjectionToken<SvgIconInfo[]> = new InjectionToken<SvgIconInfo[]>(
  "SvgIcons"
);

export interface SvgIconInfo {
  namespace?: string;
  name: string;
  svgSource: string;
}

interface SvgIconMap {
  [namespace: string]: {
    [iconName: string]: SVGElement;
  };
}

const DEFAULT_NS = "$$default";

/**
 * A custom replacement for Angular Material's `MdIconRegistry`, which allows
 * us to provide preloaded icon SVG sources.
 */
@Injectable()
export class CustomIconRegistry extends MatIconRegistry {
  private cachedSvgElements: SvgIconMap = { [DEFAULT_NS]: {} };

  constructor(
    http: HttpClient,
    sanitizer: DomSanitizer,
    @Optional() @Inject(DOCUMENT) document: Document,
    errorHandler: ErrorHandler,
    @Inject(SVG_ICONS) private svgIcons: SvgIconInfo[]
  ) {
    super(http, sanitizer, document, errorHandler);
  }

  getNamedSvgIcon(iconName: string, namespace?: string): Observable<SVGElement> {
    const nsIconMap = this.cachedSvgElements[namespace || DEFAULT_NS];
    let preloadedElement: SVGElement | undefined = nsIconMap && nsIconMap[iconName];

    if (!preloadedElement) {
      preloadedElement = this.loadSvgElement(iconName, namespace);
    }

    return preloadedElement
      ? of(preloadedElement.cloneNode(true) as SVGElement)
      : super.getNamedSvgIcon(iconName, namespace);
  }

  private loadSvgElement(iconName: string, namespace?: string): SVGElement | null {
    const svgIcon = this.svgIcons.find((icon) => {
      return namespace
        ? icon.name === iconName && icon.namespace === namespace
        : icon.name === iconName;
    });

    if (!svgIcon) {
      return null;
    }

    return this.createSVGElement(svgIcon);
  }

  private createSVGElement(svgIcon: SvgIconInfo): SVGElement {
    // IE11: Creating a new `<div>` per icon is necessary for the SVGs to work correctly in IE11.
    const div = document.createElement("DIV");

    // SECURITY: the source for the SVG icons is provided in code by trusted developers
    div.innerHTML = svgIcon.svgSource;

    const svgElement = div.querySelector("svg");

    // Cache
    const ns = svgIcon.namespace || DEFAULT_NS;
    const nsIconMap = this.cachedSvgElements[ns] || (this.cachedSvgElements[ns] = {});
    nsIconMap[svgIcon.name] = svgElement;

    return svgElement;
  }
}
