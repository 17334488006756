import { Language } from "@models/api/enums/Language.enum";
import { Lang } from "./language.type";

export const LanguagesOptions = [
  {
    name: "en",
    lang: Language.En,
    title: "English",
    isRtl: false,
  },
  {
    name: "ar",
    lang: Language.Ar,
    title: "العربية",
    isRtl: true,
  },
];

export const DEFAULT_LANG = LanguagesOptions[0];

export function isLangRoutePrefix(val: string): boolean {
  return LanguagesOptions.findIndex((x) => x.name === val) !== -1;
}

export function getLangDir(lang: Lang): any {
  const option = LanguagesOptions.find((x) => x.name === lang);

  if (!option) {
    return DEFAULT_LANG.isRtl ? "rtl" : "ltr";
  }

  return option.isRtl ? "rtl" : "ltr";
}
