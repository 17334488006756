<div class="">
  <div class="fixed notifications-header">
    <div class="flex justify-between items-center p-2 cursor-auto w-full">
      <h2 class="mb-0">{{ "header.notifications" | translate }}</h2>
      <h5
        class="cursor-pointer"
        (click)="markAllAsRead()"
        *ngIf="notificationsList?.length > 0"
      >
        {{ "header.markAllAsRead" | translate }}
      </h5>
    </div>
  </div>
  <ng-container>
    <div
      class="flex flex-col justify-center items-center content-center p-2 w-full"
      *ngIf="notificationsList?.length < 1 && loading"
    >
      <div class="w-full mt-12 flex flex-col notify-skeleton">
        <ngx-skeleton-loader count="3" appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="3" appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="3" appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="3" appearance="line"></ngx-skeleton-loader>
      </div>
    </div>
    <div
      class="flex flex-col justify-center items-center content-center p-2 w-full h-96"
      *ngIf="notificationsList?.length < 1 && !loading"
    >
      <i class="fa-solid fa-bell-slash fs-3 text-blue-900 mb-3"></i>
      <p class="text-center">{{ "general.no_notifications" | translate }}</p>
    </div>
  </ng-container>

  <div class="notification-content">
    <ul class="p-2 w-full pt-12" *ngIf="notificationsList?.length > 0">
      <ng-container
        *ngFor="let notification of notificationsList; let index = index"
      >
        <li
          class="cursor-pointer notifications"
          *ngIf="index + 1 == notificationsList?.length"
          #lastNotification
        >
          <a
            (click)="markAsRead(notification.id, notification.url)"
            [routerLink]="[notification.url]"
          >
            <div class="flex p-2 hover:bg-gray-100">
              <img
                *ngIf="notification.type == Type.Post"
                loading="lazy"
                class="w-6 h-6"
                src="assets/img/icon/post-icon.svg"
                [alt]="'header.notifications' | translate"
              />
              <img
                *ngIf="notification.type == Type.Request"
                loading="lazy"
                class="w-6 h-6"
                src="assets/img/icon/request.svg"
                [alt]="'header.notifications' | translate"
              />
              <img
                *ngIf="notification.type == Type.Massage"
                loading="lazy"
                class="w-6 h-6"
                src="assets/img/icon/message.svg"
                [alt]="'header.notifications' | translate"
              />
              <img
                *ngIf="notification.type == Type.Pay"
                loading="lazy"
                class="w-6 h-6"
                src="assets/img/icon/payment.svg"
                [alt]="'header.notifications' | translate"
              />
              <div
                class="flex justify-between flex-grow pb-2 mx-2"
                [ngClass]="{
                  'mr-2': lang === 'ar',
                  'ml-2': lang === 'en'
                }"
              >
                <div>
                  <h2 class="mb-0 leading-none">{{ notification.head }}</h2>
                  <h4 class="leading-4 my-2">{{ notification.details }}</h4>
                  <p class="text-gray-500 text-xs">
                    {{
                      (now - convertDateType(notification.createdAt)) /
                        (1000 * 60 * 60 * 24) >
                      1
                        ? (notification.createdAt | date: "short")
                        : (notification.createdAt | date: "shortTime")
                    }}
                  </p>
                </div>
                <span
                  *ngIf="!notification.isRead"
                  class="bg-red-500 h-7 text-white rounded-lg p-1 whitespace-nowrap"
                >
                  {{ "general.unread" | translate }}</span
                >
              </div>
            </div>
          </a>
        </li>
        <li
          class="cursor-pointer notifications"
          *ngIf="index + 1 != notificationsList?.length"
        >
          <a
            (click)="markAsRead(notification.id, notification.url)"
            [routerLink]="[notification.url]"
          >
            <div class="flex p-2 hover:bg-gray-100">
              <img
                *ngIf="notification.type == Type.Post"
                loading="lazy"
                class="w-6 h-6"
                src="assets/img/icon/post-icon.svg"
                [alt]="'header.notifications' | translate"
              />
              <img
                *ngIf="notification.type == Type.Request"
                loading="lazy"
                class="w-6 h-6"
                src="assets/img/icon/request.svg"
                [alt]="'header.notifications' | translate"
              />
              <img
                *ngIf="notification.type == Type.Massage"
                loading="lazy"
                class="w-6 h-6"
                src="assets/img/icon/message.svg"
                [alt]="'header.notifications' | translate"
              />
              <img
                *ngIf="notification.type == Type.Pay"
                loading="lazy"
                class="w-6 h-6"
                src="assets/img/icon/payment.svg"
                [alt]="'header.notifications' | translate"
              />
              <div
                class="flex justify-between flex-grow pb-2 mx-2"
                [ngClass]="{
                  'mr-2': lang === 'ar',
                  'ml-2': lang === 'en'
                }"
              >
                <div>
                  <h2 class="mb-0 leading-none">{{ notification.head }}</h2>
                  <h4 class="leading-4 my-2">{{ notification.details }}</h4>
                  <p class="text-gray-500 text-xs">
                    {{
                      (now - convertDateType(notification.createdAt)) /
                        (1000 * 60 * 60 * 24) >
                      1
                        ? (notification.createdAt | date: "short")
                        : (notification.createdAt | date: "shortTime")
                    }}
                  </p>
                </div>
                <span
                  *ngIf="!notification.isRead"
                  class="bg-red-500 h-7 text-white rounded-lg p-1 whitespace-nowrap"
                >
                  {{ "general.unread" | translate }}</span
                >
              </div>
            </div>
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
  <div class="notifications-footer"></div>
</div>
