import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanLoad,
  Route,
  UrlSegment,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { IdentityManager } from '@core/auth';
import { PATHS } from '@models';

@Injectable({
  providedIn: 'root',
})
export class UnauthenticatedGuard implements CanActivate, CanLoad {
  constructor(private _identityManager: IdentityManager, private _router: Router) {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.handle(segments);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.handle(next.url);
  }

  handle(segments: UrlSegment[]): Observable<boolean> {
    const user = this._identityManager.getUser();

    const authorized = !!user;

    if (authorized) {
      return from(this._router.navigate([PATHS.Home]).then(() => true));
    }

    return of(!authorized);
  }
}
