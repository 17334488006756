import { Inject, Injectable, OnDestroy, PLATFORM_ID } from "@angular/core"
import { APP_BASE_HREF, DOCUMENT, isPlatformBrowser, Location } from "@angular/common"
import { NavigationEnd, Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { Subject } from "rxjs"
import { isLangRoutePrefix, LanguagesOptions } from "@core/settings/language.helpers"
import { Lang } from "@core/settings/language.type"
import { filter, takeUntil } from "rxjs/operators"

@Injectable({
  providedIn: "root",
})
export class SeoService implements OnDestroy {
  private readonly destroy$ = new Subject<void>()
  private readonly isBrowser: boolean
  private readonly alternateLinks: HTMLLinkElement[] = []
  private canonicalLink?: HTMLLinkElement

  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private readonly translate: TranslateService,
    @Inject(APP_BASE_HREF) private readonly baseHref: Lang,
    @Inject(DOCUMENT) private readonly dom: Document,
    @Inject(PLATFORM_ID) readonly platformId: Object,
  ) {
    this.isBrowser = isPlatformBrowser(platformId)

    if (this.isBrowser) {
      this.createAlternateLink()
      this.createCanonicalLink()
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  trackCanonicalChanges(): void {
    if (!this.isBrowser) {
      return
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.canonicalLink?.setAttribute("href", this.generateCanonicalUrl())

        this.generateAlternateUrls().forEach((url, index) =>
          this.alternateLinks[index].setAttribute("hreflang", url),
        )
      })
  }

  private createAlternateLink(): void {
    this.generateAlternateUrls().forEach((url, index) => {
      this.alternateLinks[index] = this.dom.createElement("link")
      this.alternateLinks[index].setAttribute("rel", "alternate")
      this.alternateLinks[index].setAttribute("hreflang", url)

      this.dom.head.appendChild(this.alternateLinks[index])
    })
  }

  private generateAlternateUrls(): string[] {
    return LanguagesOptions.map((x) => x.name)
      .filter((lang) => lang !== this.baseHref)
      .map((lang) => {
        const currPath = this.location.path()

        if (!currPath) {
          return `${this.dom.location.origin}/${lang}`
        }

        const currPrefix = currPath.substr(1, 2)

        const p = isLangRoutePrefix(currPrefix)
          ? `/${lang}${currPath.substring(3)}`
          : `/${lang}${currPath}`

        return `${this.dom.location.origin}${p}`
      })
  }

  private createCanonicalLink(): void {
    const href = this.generateCanonicalUrl()

    this.canonicalLink = this.dom.createElement("link")
    this.canonicalLink.setAttribute("rel", "canonical")
    this.canonicalLink.setAttribute("href", href)

    this.dom.head.appendChild(this.canonicalLink)
  }

  private generateCanonicalUrl(): string {
    return this.dom.location.origin + this.location.path()
  }
}
