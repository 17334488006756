export interface IAuthResponse {
    token?: string | undefined;
    refreshToken?: string | undefined;
}

export class AuthResponse implements IAuthResponse {
    token?: string | undefined;
    refreshToken?: string | undefined;

    constructor(data?: IAuthResponse) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}