import { ITokenPayload, Role } from "@core/auth/services/token";

export class User {
  id: string;
  userName: string;
  fullName?: string;
  email: string;
  phoneNumber: string;
  created?: Date;
  picture?: string;
  roles?: Role | Role[];
  isAdmin: boolean;

  constructor(payload: ITokenPayload) {
    this.id = payload.sub;
    this.userName = payload.name;
    this.fullName = payload.fullName;
    this.email = payload.email;
    this.phoneNumber = payload.phoneNumber;
    this.created = new Date(payload.created);
    this.roles = payload.roles;
    this.isAdmin = payload.roles ? payload.roles.includes("Admin") : false;
  }
}
