import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FooterComponent } from "./footer.component";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconRegistry } from "@angular/material/icon";
import { CustomIconRegistry } from "@core/icons";
import { MatIconModule } from '@angular/material/icon';
import { SvgIconsProviders } from "@shared/svg-icons-providers";


@NgModule({
  declarations: [FooterComponent],
  imports: [CommonModule, TranslateModule, RouterModule, MatIconModule,
  ],
  exports: [FooterComponent],
  providers: [
    ...SvgIconsProviders,
    { provide: MatIconRegistry, useClass: CustomIconRegistry },
  ],
})
export class FooterModule { }
