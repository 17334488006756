import { IPageOptions } from './../../@models/PageOptions';
import { finalize } from "rxjs/operators";
import { Subscription } from "rxjs";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { Router } from "@angular/router";
import { NUMBER_OF_NOTIFICATIONS } from "@core/utils/constants";
import { TranslateService } from "@ngx-translate/core";
import { NotificationClientService } from "./api/services/notification-client.service";
import { PageInfo } from "@models/api/PageInfo";
import { NotificationType } from "./api/models/enums/NotificationType.enum";
import { PaginatedListOfNotificationVm } from './api/models/responders/view-models/PaginatedListOfNotificationVm';
import { NotificationVm } from './api/models/responders/view-models/NotificationVm';
@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Output() reset: EventEmitter<number> = new EventEmitter();
  @ViewChildren("lastNotification", { read: ElementRef })
  lastList: QueryList<ElementRef>;

  lang: string;
  notifications: boolean = false;
  notificationsList: NotificationVm[] = [];
  now;
  observer: IntersectionObserver;
  pageInfo: PageInfo;
  Type = NotificationType;
  
  _subs: Subscription[] = [];
  loading: boolean = false;
  constructor(
    private notificationsClient: NotificationClientService,
    private translateSer: TranslateService,
    private _router: Router
  ) {
    this.lang = this.translateSer.currentLang;
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  ngOnInit() {
    this.getNotifications();
    this.interSectionObserver();
  }

  ngAfterViewInit(): void {
    this._subs.push(
      this.lastList.changes.subscribe((item) => {
        if (item.last) {
          this.observer?.observe(item.last.nativeElement);
        }
      })
    );
  }

  interSectionObserver() {
    let options: IntersectionObserverInit = {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    };

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry, index) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fadeIn");
          if (entries[index]) {
            if (this.pageInfo?.pageIndex + 1 < this.pageInfo?.totalPages) {
              this.pageInfo.pageIndex++;
              this.getNotifications();
            }
          }
        }
      });
    }, options);
  }

  _getNotifications() {
    const pageOptions: IPageOptions = {
      offset: NUMBER_OF_NOTIFICATIONS,
      pageIndex: this.pageInfo?.pageIndex ?? 0,
      search: null,
      ascending: false,
      sortBy: null
    }
    this.notificationsClient
      .getPage(pageOptions)
      .subscribe((n: PaginatedListOfNotificationVm) => {
        this.pageInfo = n.pageInfo;
        n.items.forEach((n) => this.notificationsList.push(n));
      });
  }

  getNotifications() {
    this.loading = true;
    const pageOptions: IPageOptions = {
      offset: NUMBER_OF_NOTIFICATIONS,
      pageIndex: this.pageInfo?.pageIndex ?? 0,
      search: null,
      ascending: false,
      sortBy: null
    }
    this._subs.push(
      this.notificationsClient
        .getPage(pageOptions)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe((n: PaginatedListOfNotificationVm) => {
          this.pageInfo = n.pageInfo;
          n.items.forEach((n) => this.notificationsList.push(n));
          this.now = new Date();
          let x: any = this.notificationsList[0].createdAt;
        })
    );
  }

  convertDateType(date) {
    date = date as any;
    return date;
  }

  markAsRead(id, url) {
    this.notificationsClient.markAsRead(id).subscribe(
      () => {},
      (err) => console.log(err)
    );
    this._router.navigate([url]);

    this.onSuccess(-1);
  }

  markAllAsRead() {
    this.notificationsClient.markAllAsRead().subscribe(
      () => {},
      (err) => console.log(err)
    );
    this.onSuccess(0);
  }

  onSuccess(counter: number) {
    this.reset.emit(counter);
  }
}
