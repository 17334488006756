<nav class="w-100 bg-white fixed bottom-0 pt-3 px-4" style="box-shadow: 0px -1px 9px #8888884d;">
    <ul
        class="quick-links d-flex flex-row flex-nowrap justify-content-evenly align-items-center list-unstyled my-0 mx-auto">
        <li class="link">
            <a class="d-flex flex-column justify-content-center align-items-center" [routerLink]="Paths.Home">
                <i class="fa-solid fa-house-chimney"></i>
                <p class="p-2 text-center">{{ "header.home" | translate }}</p>
            </a>
        </li>
        <li class="link">
            <a class="d-flex flex-column justify-content-center align-items-center" [routerLink]="Paths.Chat">
                <i class="fa-solid fa-comment-dots"></i>
                <p class="p-2 text-center">{{ "account.chat" | translate }}</p>
            </a>
        </li>
        <li class="link">
            <a class="d-flex flex-column justify-content-center align-items-center"
                [routerLink]="Paths.CreateUpdatePost">
                <i class="fa-solid fa-circle-plus add"></i>
                <p class="p-2 text-center">{{'header.addPost' | translate}}</p>
            </a>
        </li>
        <li class="link">
            <a class="d-flex flex-column justify-content-center align-items-center" [routerLink]="Paths.MarketState">
                <i class="fa-solid fa-store"></i>
                <p class="p-2 text-center">{{ "header.marketState" | translate }}</p>
            </a>
        </li>
        <li class="link">
            <a class="d-flex flex-column justify-content-center align-items-center" [routerLink]="Paths.Account">
                <i class="fa-solid fa-user"></i>
                <p class="p-2 text-center">{{ "header.account" | translate }}</p>
            </a>
        </li>
    </ul>
</nav>