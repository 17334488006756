import { IProblemDetails, ProblemDetails } from "./problem-details";

export interface IHttpValidationProblemDetails extends IProblemDetails {
    errors?: { [key: string]: string[]; };
}

export class HttpValidationProblemDetails extends ProblemDetails implements IHttpValidationProblemDetails {
    errors?: { [key: string]: string[]; };

    constructor(data?: IHttpValidationProblemDetails) {
        super(data);
    }
}
