import { IPageOptions } from "./../@models/PageOptions";
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from "@angular/common/http";
import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ApiException } from "@models/api/api-exception";
import { ProblemDetails } from "@models/api/problem-details";
import { ValidationProblemDetails } from "@models/api/validation-problem-details";

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');

const STATUS_400 = 400;
const STATUS_404 = 404;
const STATUS_422 = 422;

@Injectable({
    providedIn: "root",
})
export class ApiService {
    private _http: HttpClient;
    private _baseUrl: string;

    constructor(
        http: HttpClient,
        @Optional() @Inject(API_BASE_URL) baseUrl?: string
    ) {
        this._http = http;
        this._baseUrl =
            baseUrl !== undefined && baseUrl !== null ? baseUrl : undefined;
    }

    getPage(
        resourceUrl: string,
        pageOptions: IPageOptions,
        options = null
    ): Observable<any> {
        let url_ = this._baseUrl + resourceUrl;
        if (pageOptions.offset !== undefined && pageOptions.offset !== null)
            url_ += "Offset=" + encodeURIComponent("" + pageOptions.offset) + "&";
        if (pageOptions.pageIndex !== undefined && pageOptions.pageIndex !== null)
            url_ +=
                "PageIndex=" + encodeURIComponent("" + pageOptions.pageIndex) + "&";
        if (pageOptions.search !== undefined && pageOptions.search !== null)
            url_ += "Search=" + encodeURIComponent("" + pageOptions.search) + "&";
        if (pageOptions.ascending === null)
            throw new Error("The parameter 'ascending' cannot be null.");
        else if (pageOptions.ascending !== undefined)
            url_ +=
                "Ascending=" + encodeURIComponent("" + pageOptions.ascending) + "&";
        if (pageOptions.sortBy !== undefined && pageOptions.sortBy !== null)
            url_ += "SortBy=" + encodeURIComponent("" + pageOptions.sortBy) + "&";
        url_ = url_.replace(/[?&]$/, "");

        const options_ = options ?? {
            headers: new HttpHeaders({
                Accept: "application/json",
            }),
        };

        return this._http
            .get(url_, options_)
            .pipe(catchError((error: HttpErrorResponse) => this.errorHandler(error)));

    }

    get(resourceUrl: string, options = null): Observable<any> {
        let url_ = this._baseUrl + resourceUrl;
        url_ = url_.replace(/[?&]$/, "");

        const options_ = options ?? {
            headers: new HttpHeaders({
                Accept: "application/json",
            }),
        };

        return this._http
            .get(url_, options_)
            .pipe(catchError((error: HttpErrorResponse) => this.errorHandler(error)));
    }

    post(resourceUrl: string, body: any, options = null): Observable<any> {
        let url_ = this._baseUrl + resourceUrl;
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        const options_ = options ?? {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Accept: "application/json",
            }),
        };

        return this._http
            .post<typeof body>(url_, content_, options_)
            .pipe(catchError((error: HttpErrorResponse) => this.errorHandler(error)));
    }

    put(resourceUrl: string, body: any, options = null): Observable<any> {
        let url_ = this._baseUrl + resourceUrl;
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        const options_ = options ?? {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        };

        return this._http
            .put<typeof body>(url_, content_, options_)
            .pipe(catchError((error: HttpErrorResponse) => this.errorHandler(error)));
    }

    postFormData(resourceUrl: string,formData: FormData,options = null): Observable<any> {
        let url_ = this._baseUrl + resourceUrl;
        url_ = url_.replace(/[?&]$/, "");

        const options_ = options ?? {
            headers: new HttpHeaders({}),
        };

        return this._http
        .post<FormData>(url_, formData, options_)
        .pipe(catchError((error: HttpErrorResponse) => this.errorHandler(error)));
    }
    
    postFormDataByPropertyName(
        resourceUrl: string,
        file: any,
        propertyName: string,
        options = null
    ): Observable<any> {
        let url_ = this._baseUrl + resourceUrl;
        url_ = url_.replace(/[?&]$/, "");

        const content_ = new FormData();

        if (file !== null && file !== undefined)
            content_.append(
                propertyName,
                file.data,
                file.fileName ? file.fileName : "Picture"
            );

        const options_ = options ?? {
            headers: new HttpHeaders({}),
        };

        return this._http
            .post<typeof file>(url_, content_, options_)
            .pipe(catchError((error: HttpErrorResponse) => this.errorHandler(error)));
    }


    putFormData(resourceUrl: string,formData: FormData,options = null): Observable<any> {
        let url_ = this._baseUrl + resourceUrl;
        url_ = url_.replace(/[?&]$/, "");

        const options_ = options ?? {
            headers: new HttpHeaders({}),
        };

        return this._http
        .put<FormData>(url_, formData, options_)
        .pipe(catchError((error: HttpErrorResponse) => this.errorHandler(error)));
    }


    putFormDataByPropertyName(
        resourceUrl: string,
        file: any,
        propertyName: string,
        options = null
    ): Observable<any> {
        let url_ = this._baseUrl + resourceUrl;
        url_ = url_.replace(/[?&]$/, "");

        const content_ = new FormData();

        if (file !== null && file !== undefined)
            content_.append(
                propertyName,
                file.data,
                file.fileName ? file.fileName : "Picture"
            );

        const options_ = options ?? {
            headers: new HttpHeaders({}),
        };

        return this._http
            .put<typeof file>(url_, content_, options_)
            .pipe(catchError((error: HttpErrorResponse) => this.errorHandler(error)));
    }

    delete(resourceUrl: string, options = null): Observable<any> {
        let url_ = this._baseUrl + resourceUrl;
        url_ = url_.replace(/[?&]$/, "");

        const options_ = options ?? {
            headers: new HttpHeaders({}),
        };

        return this._http
            .delete(url_, options_)
            .pipe(catchError((error: HttpErrorResponse) => this.errorHandler(error)));
    }

    private errorHandler(error: HttpErrorResponse): Observable<any> {
        //status
        const status = error.status;

        //headers
        let headers: any = {};
        if (error.headers) {
            for (let key of error.headers.keys()) {
                headers[key] = error.headers.get(key);
            }
        }

        //return value according error status
        if (status == STATUS_400 || status == STATUS_404) {
            return throwError(new ProblemDetails(error.error));
        } else if (status == STATUS_422) {
            return throwError(new ValidationProblemDetails(error.error));
        } else {
            //return general type of error
            const message = "An unexpected server error occurred.";
            const response = error.message;
            const result = undefined;

            return throwError(
                new ApiException(message, status, response, headers, result)
            );
        }
    }
}
