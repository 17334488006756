import { HttpValidationProblemDetails, IHttpValidationProblemDetails } from "./http-validation-problem-details";

export interface IValidationProblemDetails extends IHttpValidationProblemDetails {
    errors?: { [key: string]: string[]; };
}

export class ValidationProblemDetails extends HttpValidationProblemDetails implements IValidationProblemDetails {
    errors?: { [key: string]: string[]; };

    constructor(data?: IValidationProblemDetails) {
        super(data);
    }
}