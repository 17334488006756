import { TokenInterceptor } from "@core/interceptors/token.interceptor";
import { ToastrModule } from "ngx-toastr";
import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "src/environments/environment";
import { AngularSvgIconModule } from "angular-svg-icon";
import { FooterModule } from "./footer/footer.module";
import { HeaderModule } from "./header/header.module";
import { RouterModule } from "@angular/router";
import { SidnavModule } from "./home/sidnav/sidnav.module";
// import { ReactiveFormsModule } from "@angular/forms";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { provideMessaging, getMessaging } from "@angular/fire/messaging";
import { APP_BASE_HREF, registerLocaleData } from "@angular/common";
// import localeAR from "@angular/common/locales/ar";
import { InitializerProviders } from "@core/bootstrap/initializers";
import { API_BASE_URL } from "@core/api-service";
// registerLocaleData(localeAR);
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

const CUSTOM_MODULES = [
  FooterModule,
  HeaderModule,
  SidnavModule
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    ...CUSTOM_MODULES,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    // CoreModule,
    RouterModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideMessaging(() => getMessaging()),
    AngularSvgIconModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : navigator.language.match(/^ar-?/)
        ? "ar"
        : "en",
    }),
    ToastrModule.forRoot({
      timeOut: 10000,
      easeTime: 1000,
      positionClass: "toast-bottom-left",
      preventDuplicates: true,
    })
  ],
  providers: [
    InitializerProviders,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: API_BASE_URL, useValue: environment.apiUrl },
    {
      provide: APP_BASE_HREF,
      useFactory: (localeId: string) => localeId,
      deps: [LOCALE_ID],
    },
    {
      provide: LOCALE_ID,
      useValue: localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : navigator.language.match(/^ar-?/)
        ? "ar"
        : "en",
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
