import {
  Router,
} from "@angular/router";
import { Component } from "@angular/core";
import { PATHS } from "@models";
import { finalize } from "rxjs/operators";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Subscription } from "rxjs";
import { IdentityManager } from "@core/auth";
import { AuthToken } from "@core/auth/services/token";
import { SeoService } from "@core/services/seo.service";
import { PreloaderService } from "@core/services/preloader.service";
import { AuthResponse } from "./@core/auth/models/AuthResponse";

@UntilDestroy()
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  loaded = false;
  subscriptions: Subscription[] = [];

  constructor(
    private _identityManager: IdentityManager,
    private _router: Router,
    private _preloader: PreloaderService,
    private readonly _seo: SeoService,
  ) {}

  async ngOnInit(): Promise<any> {
  
     this._seo.trackCanonicalChanges();

    const token = this._router.parseUrl(window.location.search).queryParams[
      "token"
    ];    

    if (token) {
      this._identityManager.authorize(
        new AuthToken(new AuthResponse({ token: token, refreshToken: "" })),
        false
      );
    
      this.load().then();
    } else {
      const user = this._identityManager.getUser();
      
      // stop the whole app till you refresh the token
      if (user) {
        const result = await this._identityManager
          .refreshToken("/", window.location.pathname)
          .pipe(finalize(() => this.load().then()))
          .toPromise();

        if (!result.user) {
          this._router.navigate([PATHS.Login]);
        }

      } else {
        this.load().then();
      }
    }

  }

  async load(): Promise<any> {
    this._preloader.hide();

    this.loaded = true;
  }

}
