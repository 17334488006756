const ACCOUNT_BASE_PATH = '/Accounts';

export const AccountPaths = {
    Register : `${ACCOUNT_BASE_PATH}/Register`,
    Login : `${ACCOUNT_BASE_PATH}/Login`,
    ExternalRegistration : `${ACCOUNT_BASE_PATH}/ExternalRegistration`,
    ExternalLogin : `${ACCOUNT_BASE_PATH}/ExternalLogin`,
    ForgetPassword : `${ACCOUNT_BASE_PATH}/ForgetPassword`,
    ForgetPasswordPhone : `${ACCOUNT_BASE_PATH}/ForgetPasswordPhone`,
    ResetPassword : `${ACCOUNT_BASE_PATH}/ResetPassword`,
    ResetPasswordPhone : `${ACCOUNT_BASE_PATH}/ResetPasswordPhone`,
    GetAccount : `${ACCOUNT_BASE_PATH}`,
    ChangeName : `${ACCOUNT_BASE_PATH}`,
    ChangePassword : `${ACCOUNT_BASE_PATH}/ChangePassword`,
    ChangePhoneNumber : `${ACCOUNT_BASE_PATH}/ChangePhoneNumber`,
    ConfirmChangedPhoneNumber : `${ACCOUNT_BASE_PATH}/ConfirmChangedPhoneNumber`,
    RefreshToken : `${ACCOUNT_BASE_PATH}/RefreshToken`,
    ChangePicture : `${ACCOUNT_BASE_PATH}/ChangePicture`,
    ChangeEmailAddress : `${ACCOUNT_BASE_PATH}/ChangeEmailAddress`,
    ConfirmChangedEmail : `${ACCOUNT_BASE_PATH}/ConfirmChangedEmail`,
    GetLimitPosts : `${ACCOUNT_BASE_PATH}/GetLimitPosts`,
    PutConcurrency : `${ACCOUNT_BASE_PATH}/PutConcurrency`,
    ShowEmail : `${ACCOUNT_BASE_PATH}/ShowEmail`,
    ShowPhoneNumber : `${ACCOUNT_BASE_PATH}/ShowPhoneNumber`,
    DeleteUser : `${ACCOUNT_BASE_PATH}/DeleteUser`,
    BlockAccount : `${ACCOUNT_BASE_PATH}/BlockAccount`,
    Logout : `${ACCOUNT_BASE_PATH}/Logout`,
    DeviceTokens : `/api/DeviceTokens`,
}



