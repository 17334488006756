<header>
  <nav
    class="navbar navbar-expand-lg d-flex flex-row justify-content-between justify-content-sm-center align-items-center my-8">
    <div class="wrapper mat-elevation-z4 w-full fixed">
      <div class="container d-flex flex-row justify-content-between align-items-center px-4 sm:px-20">
        <a class="navbar-brand" routerLink="{{ Paths.Home }}">
          <mat-icon class="w-32 h-auto" svgIcon="claro-logo"></mat-icon>
        </a>

        <!-- collapsed area -->
        <div class="navbarContent hidden lg:flex flex-grow-0" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <a class="nav-link" routerLink="{{ Paths.Home }}" routerLinkActive="active-route"
                [routerLinkActiveOptions]="{ exact: true }">{{ "header.home" | translate }}</a>
              <div class="underline"></div>
            </li>
            <li class="nav-item" (click)="saleRent(SellingDetails.Sale)">
              <a class="nav-link" [class.active-route]="sale">{{
                "header.forSale" | translate
                }}</a>
              <div class="underline"></div>
            </li>
            <li class="nav-item" (click)="saleRent(SellingDetails.Rent)">
              <a class="nav-link" [class.active-route]="rent">{{
                "header.forRent" | translate
                }}</a>
              <div class="underline"></div>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="Paths.MarketState" routerLinkActive="active-route">
                {{ "header.marketState" | translate }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="Paths.Questions" routerLinkActive="active-route">{{
                "header.commonQuestions" | translate }}</a>
              <div class="underline"></div>
            </li>
          </ul>

          <ul class="second-nav d-none d-lg-flex">
            <li class="profile h-10 pt-1 m-1">
              <!-- If Signed in -->
              <a *ngIf="account" [routerLink]="Paths.Account" class="flex align-items-center">
                <img [src]="account.imageUrl || 'assets/img/default.jpg' | safeUrl"
                  onerror="this.src='assets/img/default.jpg';" [alt]="'accountDetails.image' | translate"
                  loading="lazy" />
                <span class="mx-1">{{ "login.hi" | translate }}</span>{{ account.name.firstName }}
              </a>
              <!-- If not Signed in -->
              <a *ngIf="!account" [routerLink]="Paths.Login" class="flex align-items-center">
                <i class="fa-solid fa-circle-user fs-4 mx-1"></i>
                <span>{{ "login.login" | translate }}</span>
              </a>
              <div class="pop-profile" [ngStyle]="{
                  left: lang == 'en' ? '-6.3rem' : 'unset',
                  right: lang == 'ar' ? '-6.3rem' : 'unset'
                }">
                <!-- If Signed in -->
                <ul *ngIf="account">
                  <li class="first">
                    <a [routerLink]="Paths.Account" class="flex align-items-center">
                      <img [src]="
                          account.imageUrl || 'assets/img/default.jpg' | safeUrl
                        " [alt]="'accountDetails.image' | translate" loading="lazy" />
                      {{ account.name.firstName + " " + account.name.lastName }}
                    </a>
                  </li>

                  <li class="second">
                    <a (click)="logout()">
                      <i style="color: #002039" class="fa-solid fa-arrow-right-from-bracket"></i>
                      {{ "account.logout" | translate }}
                    </a>
                  </li>
                </ul>

                <!-- If not Signed in -->
                <ul *ngIf="!account">
                  <li class="first">
                    <a [routerLink]="Paths.Login">
                      <i style="color: #002039" class="fa-solid fa-arrow-right-to-bracket mx-2"></i>
                      {{ "login.login" | translate }}</a>
                  </li>
                  <li class="second">
                    <a [routerLink]="Paths.SignUp">
                      <i style="color: #002039" class="fa-solid fa-user-plus mx-2"></i>
                      {{ "login.signUp" | translate }}
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li [class.hidden]="!account" class="m-1 h-6 notifications" [matBadge]="activeNotifications"
              [matBadgeHidden]="activeNotifications < 1" matBadgeColor="warn" matBadgePosition="before"
              matBadgeSize="small" (click)="showNotifications()" [ngClass]="{'notifications-border': notifications}">
              <button class="notification" matTooltip="{{ 'header.notifications' | translate }}">
                <i #toggleButton class="fa-solid fa-bell fs-4"></i>
              </button>
              <div class="notificationsContainer" #menu [ngClass]="{
                  '-right-56': lang === 'en',
                  '-left-56': lang === 'ar'
                }">
                <app-notifications *ngIf="notifications" (reset)="resetActiveNotification($event)"></app-notifications>
              </div>
            </li>
            <li class="m-1 h-6">
              <a (click)="changeLang()" class="lang flex items-center"
                matTooltip="{{ 'header.changeLang' | translate }}">
                <i class="fa-solid fa-globe fs-4 mx-1"></i>
                <span class="fs-4">{{ "header.lang" | translate }}</span>
              </a>
            </li>
            <li class="m-1">
              <a [routerLink]="Paths.CreateUpdatePost"
                class="add-post rounded-pill bg-danger flex flex-row flex-nowrap px-1">
                <i class="fa-solid fa-circle-plus text-white"></i>
                <span class="m-1 text-white fw-bold">{{
                  "header.addPost" | translate
                  }}</span>
              </a>
            </li>
          </ul>
        </div>

        <ul class="third-nav d-flex flex-row flex-nowrap justify-content-between flex lg:hidden mt-2">
          <li class="flex profile">
            <!-- If Signed in -->
            <a *ngIf="account" [routerLink]="Paths.Account" class="flex align-items-center pb-1">
              <img *ngIf="account.imageUrl" [src]="account.imageUrl  | safeUrl"
                [alt]="'accountDetails.image' | translate" onerror="this.src='assets/img/default.jpg';"
                loading="lazy" />
              <i *ngIf="!account.imageUrl" class="fa-solid fa-user default-image"></i>
            </a>
          </li>
          <li [class.hidden]="!account" class="mx-3" [matBadge]="activeNotifications"
            [matBadgeHidden]="activeNotifications < 1" matBadgeColor="warn" matBadgePosition="before"
            matBadgeSize="small" (click)="showNotifications()" [ngClass]="{'notifications-border': notifications}">
            <a class="notification">
              <i #toggleButton2 class="fa-solid fa-bell fs-4"></i>
            </a>
            <div class="notificationsContainer mat-elevation-z8" [ngClass]="{
                flex: notifications === true,
                hidden: notifications === false,
                '-right-12': lang === 'en',
                '-left-16': lang === 'ar'
              }" #menu2>
              <app-notifications *ngIf="notifications" (reset)="activeNotifications = $event"></app-notifications>
            </div>
          </li>
          <li>
            <a (click)="changeLang()" class="lang flex h-6 items-center">
              <i class="fa-solid fa-globe fs-4 mx-1"></i>
              <span class="fs-4">{{ "header.lang" | translate }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>