export interface IRefreshTokenCommand {
    refreshToken?: string | undefined;
}

export class RefreshTokenCommand implements IRefreshTokenCommand {
    refreshToken?: string | undefined;

    constructor(data?: IRefreshTokenCommand) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}
