import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

export enum SellingDetails{
  Sale = 0,
  Rent = 1
}

@Injectable()
export class FilterService {
  saleOrRent: BehaviorSubject<SellingDetails> = new BehaviorSubject(null);
  constructor() { }
}
