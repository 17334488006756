import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./header.component";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { SvgIconsProviders, CustomIconRegistry } from "@core/icons";
import {MatBadgeModule} from '@angular/material/badge';
import {MatTooltipModule} from '@angular/material/tooltip';
import { NotificationsComponent } from "./notifications/notifications.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { SafeUrlPipe } from "@shared/pipes/safe-url.pipe";
import { FilterService } from "@core/services/filter.service";
import { RealTimeService } from "@core/services/real-time.service";
import { NotificationClientService } from "./notifications/api/services/notification-client.service";

@NgModule({
  declarations: [HeaderComponent, NotificationsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    SafeUrlPipe,
    MatBadgeModule,
    MatTooltipModule,
    NgxSkeletonLoaderModule,
    MatIconModule
  ],
  exports: [HeaderComponent, NotificationsComponent],
  providers: [
    ...SvgIconsProviders,
    { provide: MatIconRegistry, useClass: CustomIconRegistry },
    FilterService, RealTimeService, NotificationClientService
  ],
})
export class HeaderModule {}
