export const PATHS = {
  Home: "/",
  Page404: "/404",
  Page403: "/403",
  Page500: "/500",
  MainPage: "/filter",
  SeeMore: "/more-posts",
  MainMap: "/map",
  Claro: "/filter?claro=true",
  ForSale: "/filter/all-types/all-categories/for-sale",
  ForRental: "/filter/all-types/all-categories/for-rental",
  Post: "/post/view",
  PostNotFound: "/posts/404",
  CreateUpdatePost: "/post",
  Policy: "/privacy-policy",
  Terms: "/terms-conditions",
  MarketState: "/market-state",
  Questions: "/common-questions",
  Who: "/about-us",
  ForgotPassword:'/password/forgot-password',
  ResetPassword:'/password/reset-password',
  Chat: "/chats",
  Account: "/account",
  MyPosts: "posts",
  MyRequests: "requests",
  Payment: "/post/payment",
  PayAsCustomer: "/post/pay",
  BookAppointment: "/book-appointment",
  Login: "/login",
  SignUp: "/signup",
  SignUpWithPhone: "/signup/register-with-phone",
  SelectCertification: "/post/add-certification",
  ChangeEmail: "change-email",
  ChangePassword: "change-password",
  ChangePhone: "change-phone-number",
  RequestsOfCahnges: "/account/requests",
  PostCertificationsRequests: "post-certifications",
  NeedToPayRequests: "need-to-pay",
  NeedToBookRequests: "need-to-book",
  Logout: "/",
};

export const ACTIONS = {
  Login: "login",
};

const ACTION = "action";
const RETURN_URL_TYPE = "returnUrl";
const MESSAGE_URL_TYPE = "message";

export const QUERY_PARAMETER_NAMES = {
  Action: ACTION,
  ReturnUrl: RETURN_URL_TYPE,
  Message: MESSAGE_URL_TYPE,
};
