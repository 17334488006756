import { APP_INITIALIZER, Inject, LOCALE_ID } from "@angular/core";
import { TranslateLoaderService } from "./translation-loader.service";
import { APP_BASE_HREF } from "@angular/common";
import {
  DEFAULT_LANG,
  isLangRoutePrefix as isRouteLang,
} from "../settings/language.helpers";
import { TranslateService } from "@ngx-translate/core";


function initializeAppFactory(loader: TranslateLoaderService): () => Promise<any> {
  return () => loader.load()
 }
 
export const InitializerProviders = [
  /** Initialize Base Href to current language */
  {
    provide: APP_BASE_HREF,
    useFactory: (localeId: string) => localeId,
    deps: [LOCALE_ID],
  },
  /** Initialize translation based on the current language */
  {
    provide: APP_INITIALIZER,
    useFactory:initializeAppFactory,
    deps: [TranslateLoaderService],
    multi: true,
  },
  /** Initialize the locale of your application */
  {
    provide: LOCALE_ID,
    useFactory: (translate: TranslateService) => {
      const urlLang = location?.pathname?.split("/")[1];

      if (urlLang && isRouteLang(urlLang)) {
        localStorage.setItem(
          "lang",
          urlLang === "ar"
            ? urlLang
            : urlLang === "en"
            ? "en"
            : DEFAULT_LANG.name
        );
        return urlLang === "ar"
          ? urlLang
          : urlLang === "en"
          ? "en"
          : DEFAULT_LANG;
      }

      const userDefaultLang = localStorage.getItem("lang");

      let browserLang = translate.getBrowserLang();

      if (userDefaultLang) browserLang = userDefaultLang;

      localStorage.setItem("lang", browserLang); // set lang for defult user in local storage

      return browserLang.match(/^ar-?/)
        ? "ar"
        : browserLang.match(/^en-?/)
        ? "en"
        : DEFAULT_LANG;
    },
    deps: [TranslateService],
  },
];
