import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Messaging, onMessage } from '@angular/fire/messaging';

@Injectable()
export class RealTimeService {
  token: string;
  sub: Subscription;
  callbacks: ((message: any) => void)[] = [];

  keys = {
    hidden: 'visibilitychange',
    webkitHidden: 'webkitvisibilitychange',
    mozHidden: 'mozvisibilitychange',
    msHidden: 'msvisibilitychange',
  };

  constructor(
    private _afMessaging: Messaging,
  ) {
  }

  onMessage(callback: (message: any) => void): void {
    this.callbacks.push(callback);
    onMessage(this._afMessaging, (message: any) => {
      for (const call of this.callbacks) {
        call(message);
      }
    })
  }

  onNotification(callback: (message: any) => void): void {
    this.callbacks.push(callback);
    onMessage(this._afMessaging, (message: any) => {
      for (const call of this.callbacks) {
        call(message);
      }
    })
  }

  onReturn(callback: (event: any) => void): boolean {
    let eventKey = this.getEventName();

    if (callback)
      document.addEventListener(eventKey, (e) => {
        if (!document.hidden) {
          callback(e);
        }
      });

    return !!eventKey;
  }

  unsubscribe(): void {
    this.sub.unsubscribe();

    document.removeAllListeners(this.getEventName());
  }

  getEventName(): string {
    for (const stateKey in this.keys) {
      if (stateKey in document) {
        return this.keys[stateKey];
      }
    }

    return null;
  }
}
