import { SVG_ICONS, SvgIconInfo } from "./custom-icon-registry";
import { InjectionToken } from "@angular/core";

/**
 * These are the hardcoded inline svg sources to be used by the `<mat-icon>` component.
 * For compatibility with the MatIcon, please ensure that the SVG source has the following attributes:
 *
 * * `class="ms-svg-icon"`
 * * `xmlns="http://www.w3.org/2000/svg"`
 * * `focusable="false"`                   (disable IE11 default behavior to make SVGs focusable)
 * * `height="100%"`                       (the default)
 * * `width="100%"`                        (the default)
 * * `preserveAspectRatio="xMidYMid meet"` (the default)
 * 
 * {
      provide: SVG_ICONS,
      useValue: {
        name: ``,
        svgSource: ``
      },
      multi: true,
    },
 *
 * * Useage: <mat-icon svgIcon="attach_file"></mat-icon>
 */

// tslint:disable: max-line-length quotemark
export const SvgIconsProviders: Array<{
  provide: InjectionToken<SvgIconInfo[]>;
  useValue: SvgIconInfo;
  multi: boolean;
}> = [];
