export const environment = {
  production: true,
  isTrial: true,
  apiUrl: 'https://api.claroeg.com/api',
  googleClientId:
    "589244700518-4i0cmv9f0o211k85j889iinpioo0cah3.apps.googleusercontent.com",
  facebookAppId: "767393061285117",
  firebase: {
    apiKey: "AIzaSyAJLyx4LGy2qkKBufYx--L_ZzpbLbIPVu4",
    authDomain: "claroeg-4d865.firebaseapp.com",
    projectId: "claroeg-4d865",
    storageBucket: "claroeg-4d865.appspot.com",
    messagingSenderId: "589244700518",
    appId: "1:589244700518:web:423bd09e7081533906daa7",
    measurementId: "G-4GJ8HXXHD9"
  },
  mapKey:"AIzaSyAg7MQtu0bInheGnXtK7vNp_eJ6vZQcQ18",
  vapidKey:"BIKt9gLYH-X5UttGA8kPIKP02gz1wMKEwWTc0_RXmrenXxvwbqC30aozKk4EKofR8F6rloxxxFiDvcH7pGISQbI"
};
