import { Injectable } from "@angular/core";
import { ApiService } from "@core/api-service";
import { Observable } from "rxjs";
import { AccountDto } from "@core/auth/models/responders/dtos/AccountDto";
import { AuthResponse } from "@core/auth/models/AuthResponse";
import { DeviceTokensAddDeviceTokenCommand } from "@models/api/commands/DeviceTokensAddDeviceTokenCommand";
import { RefreshTokenCommand } from "@core/auth/models/commands/RefreshTokenCommand";
import { AccountPaths } from "../api-account-paths";


@Injectable({
    providedIn: "root",
})
export class AccountClientService {
    constructor(private http: ApiService) { }

    get(): Observable<AccountDto> {
        const url_ = AccountPaths.GetAccount;

        return this.http.get(url_);
    }

    refreshToken(command: RefreshTokenCommand): Observable<AuthResponse> {
        const url_ = AccountPaths.RefreshToken;

        return this.http.post(url_, command);
    }

    postDeviceToken(command: DeviceTokensAddDeviceTokenCommand): Observable<void> {
        const url_ = AccountPaths.DeviceTokens;

        return this.http.post(url_, command);
    }

    logout(): Observable<void> {
        const url_ = AccountPaths.Logout;

        return this.http.delete(url_);
    }

}
