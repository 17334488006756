import { PATHS } from "@models";
import { Component } from "@angular/core";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  Paths = PATHS;
  isTrial: boolean;
  lang: string;

  constructor(
    private translateSer: TranslateService,

  ) {
    this.isTrial = environment.isTrial;
    this.lang = this.translateSer.currentLang;
  }
}
