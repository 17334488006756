import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanLoad,
  Route,
  UrlSegment,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IdentityManager } from '@core/auth';
import { LocalStorageService } from '@core/services/local-storage.service';
import { PATHS, QUERY_PARAMETER_NAMES } from '@models';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeGuard implements CanActivate, CanLoad {
  constructor(
    private _identityManager: IdentityManager,
    private _router: Router,
    private _storageService: LocalStorageService
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    const returnUrl = segments.reduce(
      (path, currentSegment) => `${path}/${currentSegment.path}`,
      ''
    );
    return this.handle(returnUrl);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.handle(state.url);
  }

  private handle(returnUrl: string): Observable<boolean> {
    const user = this._identityManager.getUser();

    const authorized = !!user;

    if(returnUrl.includes('/view')){
      return of(true);
    }
    else if (!authorized) {
      // return this._identityManager.forceLogout('/', returnUrl);
      this._router.navigate([PATHS.Login], {
        queryParams: {
          [QUERY_PARAMETER_NAMES.ReturnUrl]: !!returnUrl ? returnUrl : null,
        },
      });
      //return this._identityManager.forceLogout(this._router.url, returnUrl);
    }
    return of(authorized);
  }
}
