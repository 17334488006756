import { NgModule } from "@angular/core";
import {
  Router,
  RouterModule,
  Routes,
} from "@angular/router";
import { AuthorizeGuard, UnauthenticatedGuard } from "@core/auth";

const routes: Routes = [
  {
    path: "account",
    canLoad: [AuthorizeGuard],
    canActivate: [AuthorizeGuard],
    loadChildren: () =>
      import("./profile/account.module").then((m) => m.AccountModule),
  },
  {
    path: "chats",
    canLoad: [AuthorizeGuard],
    canActivate: [AuthorizeGuard],
    loadChildren: () => import("./chat/chat.module").then((m) => m.ChatModule),
  },
  {
    path: "payment-details",
    canLoad: [AuthorizeGuard],
    canActivate: [AuthorizeGuard],
    loadChildren: () =>
      import("./payment-details/payment.module").then((m) => m.PaymentModule),
  },
  {
    path: "book-appointment",
    canLoad: [AuthorizeGuard],
    canActivate: [AuthorizeGuard],
    loadChildren: () =>
      import("./posts/book-appointment/book-appointment.module").then(
        (m) => m.BookAppointmentModule
      ),
  },
  {
    path: "post",
    canLoad: [AuthorizeGuard],
    canActivate: [AuthorizeGuard],
    loadChildren: () =>
      import("./posts/posts.module").then((m) => m.PostsModule),
  },
  {
    path: "market-state",
    loadChildren: () =>
      import("./pages/market-state/market-state.module").then(
        (m) => m.MarketStateModule
      ),
  },
  {
    path: "about-us",
    loadChildren: () =>
      import("./pages/about-us/about-us.module").then((m) => m.AboutUsModule),
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./pages/policy/policy.module").then((m) => m.PolicyModule),
  },
  {
    path: "terms-conditions",
    loadChildren: () =>
      import("./pages/terms-and-conditions/terms-and-conditions.module").then(
        (m) => m.TermsAndConditionsModule
      ),
  },
  {
    path: "common-questions",
    loadChildren: () =>
      import("./pages/questions/questions.module").then(
        (m) => m.QuestionsModule
      ),
  },
  {
    path: "login",
    canLoad: [UnauthenticatedGuard],
    canActivate: [UnauthenticatedGuard],
    loadChildren: () =>
      import("./account-managment/login/login.module").then(
        (m) => m.LoginModule
      ),
  },
  {
    path: "signup",
    canLoad: [UnauthenticatedGuard],
    canActivate: [UnauthenticatedGuard],
    loadChildren: () =>
      import("./account-managment/signup/signup.module").then(
        (m) => m.SignupModule
      ),
  },
  {
    path: "password",
    canLoad: [UnauthenticatedGuard],
    canActivate: [UnauthenticatedGuard],
    loadChildren: () =>
      import(
        "./account-managment/manage-password/manage-password.module"
      ).then((m) => m.ManagePasswordModule),
  },
  {
    path: "404",
    canLoad: [],
    loadChildren: () =>
      import("./@shared/components/not-found/not-found.module").then(
        (m) => m.NotFoundModule
      ),
  },
  {
    path: "",
    canLoad: [],
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
    //pathMatch: "full"
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          // Ignore language perfix on routing
          matcher: (segments) => {
            if (
              segments.length > 0 &&
              (segments[0].path === "en" || segments[0].path === "ar")
            ) {
              const consumed = [segments[0]];
              segments.splice(0, 1);
              return { consumed };
            }

            return { consumed: [] };
          },
          children: routes,
        },
      ],
      {
        scrollPositionRestoration:'top',
        // preloadingStrategy: PreloadAllModules,
        // scrollPositionRestoration: 'enabled',
        // relativeLinkResolution: "corrected",
        // initialNavigation: "enabled",
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {

}
