<footer id="footer" class="px-4 pt-8 pb-24 lg:px-8 overflow-hidden d-flex justify-content-evenly md:px-4 lg:pb-4">
  <div class="footer-container">
    <div class="footer-wrap overflow-hidden flex flex-col lg:flex-row justify-content-between">
      <ul class="third-section list-unstyled flex flex-col justify-between order-3 lg:order-1">
        <li class="fs-5 mb-4">{{'footer.follow'| translate}}</li>
        <li class="mb-4">
          <ul class="list-unstyled d-flex justify-content-between align-items-center p-0 icons">
            <li class="bg-white shadow rounded-circle">
              <a href="https://www.facebook.com/profile.php?id=100089254864900&mibextid=ZbWKwL" target="_blank">
                <mat-icon class="w-4 h-4" svgIcon="facebook-footer"></mat-icon>
              </a>
            </li>
            <li class="mx-1 bg-white shadow rounded-circle">
              <a href="https://twitter.com/Claro_Egypt?t=_WVZdKrf9hEUWQ5sUj-qsg&s=09" target="_blank">
                <mat-icon class="w-4 h-4" svgIcon="twitter-footer"></mat-icon>
              </a>
            </li>
            <li class="mx-1 bg-white shadow rounded-circle">
              <a href="https://www.instagram.com/claroegy/?igshid=NDk5N2NlZjQ%3D" target="_blank">
                <mat-icon class="w-4 h-4" svgIcon="instagram-footer"></mat-icon>
              </a>
            </li>
            <li class="bg-white shadow rounded-circle">
              <a href="https://www.linkedin.com/company/claro-real-estates" target="_blank">
                <mat-icon class="w-4 h-4" svgIcon="linkedin-footer"></mat-icon>
              </a>
            </li>
          </ul>
        </li>
        <div class="flex w-2/3 mb-4">
          <li class="" draggable="true"
            [ngClass]="{'mr-3': lang === 'en',  'mr-0': lang === 'ar','ml-3': lang === 'ar', 'ml-0': lang === 'en' }">
            <a class="" href="https://play.google.com/store/apps/details?id=com.claro.eg" target="_blank">
              <mat-icon class="w-28 h-auto" svgIcon="googlePlay"></mat-icon>
            </a>
          </li>
          <li class="">
            <a class="" href="https://apps.apple.com/us/app/claro-eg/id6447195527" target="_blank">
              <mat-icon class="w-28 h-auto" svgIcon="appStore"></mat-icon>
            </a>
          </li>
        </div>
        <p class="w-max text-gray-500">{{'footer.copyrights' | translate}}</p>

      </ul>
      <ul class="first-section list-unstyled flex flex-col justify-between order-2 lg:mb-0 mb-4">
        <li class="fs-5 mb-3"><a class="" [routerLink]="[Paths.Who]">{{ 'footer.who' | translate }}</a></li>
        <li class="fs-5 mb-3"><a class="" [routerLink]="[Paths.Terms]">{{ 'footer.termsConditions' | translate
            }}</a></li>
        <li class="fs-5 mb-3"><a class="" [routerLink]="[Paths.Policy]">{{ 'footer.privacyPolicy' | translate
            }}</a></li>
        <li class="fs-5 mb-3"><a class="" [routerLink]="[Paths.MarketState]">{{ 'footer.marketState' |
            translate }}</a></li>
        <li class="fs-5 mb-3"><a class="" [routerLink]="[Paths.Questions]">{{ 'footer.commonQuestions' |
            translate }}</a></li>
      </ul>
      <ul class="second-section list-unstyled order-1 lg:order-3 mb-4 lg:mb-0">
        <li class="fs-5 h-full flex flex-col justify-between">
          <ul class="flex mb-4">
            <li>
              <a href="https://www.rics.org/mena/surveying-profession/global-professional-network/rics-tech-partner-programme/"
                target="_blank">
                <mat-icon class="sm:w-32 w-24 h-auto" svgIcon="RICS-partner"></mat-icon>
              </a>
            </li>
            <li>
              <a href="https://www.ivsc.org/members/" target="_blank">
                <!-- <mat-icon class="sm:w-32 w-24 mx-2 sm:mx-4 h-auto" svgIcon="IVSC"></mat-icon> -->
                <img src="./../../assets/img/new-ivsc.png" class="sm:w-32 w-24 mx-2 sm:mx-4 h-auto" alt="IVSC">
              </a>
            </li>
            <li>
              <a href="https://www.ricsfirms.com/office/225724/Claro-Real-Estate-Company" target="_blank">
                <mat-icon class="sm:w-32 w-24 h-auto" svgIcon="RICS"></mat-icon>
              </a>
            </li>
          </ul>

          <ul id="links" class="flex flex-col flex-grow justify-around">
            <li><a target="_blank" href="mailto:contact@claroeg.com">contact@claro.eg</a></li>
            <li><a target="_blank" href="mailto:support@claroeg.com">support@claro.eg</a></li>
            <li><a target="_blank" href="https://wa.me/201550537070" dir="ltr">+20
                15 50 53 70 70</a></li>
          </ul>
        </li>
      </ul>

    </div>
  </div>
</footer>