import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { Location } from "@angular/common";
import {
  DEFAULT_LANG,
  isLangRoutePrefix,
  LanguagesOptions,
} from "@core/settings/language.helpers";
import { Lang } from "@core/settings/language.type";
import { Language } from "@models/api/enums/Language.enum";

@Injectable({
  providedIn: "root",
})
export class LocalizationService {
  constructor(
    private readonly _location: Location,
    @Inject(LOCALE_ID) private readonly currentLang: Lang
  ) {}

  changeLang(): void {
    const lang = LanguagesOptions.find((c) => c.name !== this.currentLang);
    this.setLang(lang.name);
  }

  resetLang(): void {
    this.setLang(DEFAULT_LANG.name);
  }

  setLang(lang: string): void {
    if (lang === this.currentLang) {
      return;
    }
    
    localStorage.setItem('lang',lang)
    const currPath = this._location.path(true);

    if (!currPath) {
      location.pathname = `/${lang}`;
      return;
    }

    const currPrefix = currPath.substring(1, 3);

    const newPathname = isLangRoutePrefix(currPrefix)
      ? `/${lang}${currPath.substring(3)}`
      : `/${lang}${currPath}`;

    // location.pathname = newPathname;
    location.assign(newPathname)
  }

  getLang(): Language {
    const output =
      LanguagesOptions.find((c) => c.name === this.currentLang) || DEFAULT_LANG;

    return output.lang;
  }
}
